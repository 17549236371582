import { ReactNode, Suspense } from 'react'
import { Spinner } from 'react-bootstrap'
import { useAdmin } from '../hooks/admin'
import ErrorBoundary from './ErrorBoundar'
import Footer from './Footer'
import NavBar from './NavBar'
import PreviewBar from './PreviewBar'

interface Props {
  children: ReactNode
}

function FullPageSpinner() {
  return (
    <div className="padding-top-navbar minh-100 d-flex justify-content-center">
      <div className="p-4">
        <Spinner variant="primary" />
      </div>
    </div>
  )
}

export default function Layout({ children }: Props) {
  const { adminKey } = useAdmin()
  return (
    <>
      {adminKey && <PreviewBar />}
      <NavBar />
      <ErrorBoundary>
        <Suspense fallback={<FullPageSpinner />}>
          <ErrorBoundary>
            <Suspense fallback={<FullPageSpinner />}>{children}</Suspense>
          </ErrorBoundary>
          <Footer />
        </Suspense>
      </ErrorBoundary>
    </>
  )
}
