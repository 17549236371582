import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { Routes, Route, useLocation } from 'react-router-dom'
import Layout from './components/Layout'
import NotFound from './components/NotFound'
import Contact from './pages/Contact'
import Home from './pages/Home'
import Professionals from './pages/Professionals'
import Office from './pages/Office'
import WorkingAreas from './pages/WorkingAreas'
import Article from './pages/Article'
import Articles from './pages/Articles'

export default function App() {
  const location = useLocation()
  // Scroll to top when page change
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])
  return (
    <>
      <Helmet>
        <title>Studio Legale Avvocato Micol Missana</title>
        <meta
          name="description"
          content="Studio Legale Avvocato Micol Missana, Avvocato Civilista Lecco"
        />
      </Helmet>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contatti" element={<Contact />} />
          <Route path="/professionisti" element={<Professionals />} />
          <Route path="/studio" element={<Office />} />
          <Route path="/aree-di-competenza" element={<WorkingAreas />} />
          <Route path="/articoli" element={<Articles />} />
          <Route path="/articolo/:slug" element={<Article />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Layout>
    </>
  )
}
