import {
  APIPlace,
  APIProfessional,
  APIService,
  APIShortArticle,
  APIWebSiteConfig,
  CombinedWebSiteConfig,
} from '../../src/models'
import { ContentfulConfig, curryGetEntries, curryGetEntry } from './contentful'
import { clientFnx } from './fnx'
import { contentfulCached, getContentfulConifg } from './utils'

async function computeGetConfig(
  cc: ContentfulConfig
): Promise<CombinedWebSiteConfig> {
  const getEntry = curryGetEntry(cc)
  const getEntries = curryGetEntries(cc)

  const result = await Promise.all([
    getEntry<APIWebSiteConfig>('config'),
    getEntries<APIService>('service', {
      order: 'fields.order',
    }),
    getEntries<APIPlace>('place', {
      order: 'fields.title',
    }),
    getEntries<APIProfessional>('professional', {
      order: 'fields.order',
    }),
    getEntries<APIShortArticle>('article', {
      order: '-fields.date',
      select: [
        'sys.id',
        'sys.updatedAt',
        'fields.title',
        'fields.description',
        'fields.slug',
        'fields.date',
      ].join(','),
    }),
  ])
  return {
    ...result[0],
    services: result[1],
    places: result[2],
    professionals: result[3],
    articles: result[4],
  }
}

async function getConfigServer({ adminKey }: { adminKey?: string } = {}) {
  const cc = getContentfulConifg(adminKey)
  return contentfulCached('config', () => computeGetConfig(cc), cc.preview)
}

export default import.meta.env.SSR
  ? getConfigServer
  : (clientFnx('/api/x/getConfig') as typeof getConfigServer)
