import { useConfig } from '../../hooks/config'
import styles from './Footer.module.css'

export default function Footer() {
  const config = useConfig()
  return (
    <div className={styles.Footer}>
      <div>
        © Avvocato Micol Missana {new Date().getFullYear()} • P.IVA {config.piva}
      </div>
      <div>
        Made by{' '}
        <a rel="noreferrer" target="_blank" href="https://github.com/gffuma">
          @gffuma
        </a>
      </div>
    </div>
  )
}
