import './styles/theme.scss'
import './styles/index.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import {
  hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { StrictMode } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import ReactDOM from 'react-dom/client'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { AdminProvider } from './hooks/admin'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      staleTime: Infinity,
      suspense: true,
      retry: false,
      structuralSharing: false,
    },
  },
})

// Hydrate data from SSR
hydrate(
  queryClient,
  JSON.parse(document.querySelector('script.rq_data')!.textContent!)
)
const admData = JSON.parse(
  document.querySelector('script.adm_data')!.textContent!
)

ReactDOM.hydrateRoot(
  document.getElementById('root')!,
  <StrictMode>
    <AdminProvider config={admData}>
      <HelmetProvider>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </BrowserRouter>
      </HelmetProvider>
    </AdminProvider>
  </StrictMode>
)
