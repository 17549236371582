import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import BsNavbar from 'react-bootstrap/Navbar'
import { Link, NavLink } from 'react-router-dom'
import styles from './NavBar.module.css'

export default function NavBar() {
  return (
    <BsNavbar
      className={styles.AvvNavbar}
      fixed="top"
      collapseOnSelect
      expand="lg"
    >
      <Container fluid>
        <BsNavbar.Brand as={Link} to="/" className="text-primary">
          Avvocato Micol Missana
        </BsNavbar.Brand>
        <BsNavbar.Toggle aria-controls="responsive-navbar-nav" />
        <BsNavbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={NavLink} to="/studio" href="#studio" active={false}>
              Lo Studio
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/professionisti"
              href="#professionisti"
              active={false}
            >
              I Professionisti
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/aree-di-competenza"
              href="#ruolo"
              active={false}
            >
              Aree di competenza
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/articoli"
              href="#articoli"
              active={false}
            >
              Articoli
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/contatti"
              href="#contatti"
              active={false}
            >
              Contatti
            </Nav.Link>
          </Nav>
        </BsNavbar.Collapse>
      </Container>
    </BsNavbar>
  )
}
