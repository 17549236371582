import ky from 'ky'

export class FnxError extends Error {
  public readonly status: number

  constructor(message?: string, options?: ErrorOptions & { status: number }) {
    const { status, ...passOptios } = options || {}
    super(message, passOptios)
    this.status = status ?? 500
  }
}

export function clientFnx<P extends URLSearchParams = any, R = any>(
  endpoint: string
) {
  return (searchParams: P) =>
    ky
      .get(endpoint, {
        // FIXME: https://github.com/sindresorhus/ky/issues/366
        ...{ credentials: undefined },
        searchParams,
        retry: 0,
      })
      .then((r) => r.json<R>())
}

export function serverApiFnx<P = any, R = any>(
  method: (params: P) => Promise<R>
) {
  return async (request: Request) => {
    const params = Object.fromEntries(
      new URL(request.url).searchParams.entries()
    )
    try {
      const data = await method(params as any)
      return new Response(JSON.stringify(data), {
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        },
      })
    } catch (error: any) {
      return new Response(JSON.stringify({ error: error.message }), {
        status: error.status ?? 500,
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        },
      })
    }
  }
}
