import { APIArticle } from '../../src/models'
import { DoesNotExistsError, getEntry } from './contentful'
import { clientFnx } from './fnx'
import { contentfulCached, getContentfulConifg } from './utils'

async function getArticleServer({
  adminKey,
  slug,
}: {
  adminKey?: string
  slug?: string
}) {
  if (!slug) {
    throw new DoesNotExistsError()
  }
  const cc = getContentfulConifg(adminKey)

  return contentfulCached(
    `article/${slug}`,
    () =>
      getEntry<APIArticle>(cc, 'article', {
        'fields.slug': slug,
      }),
    cc.preview
  )
}

export default import.meta.env.SSR
  ? getArticleServer
  : (clientFnx('/api/x/getArticle') as typeof getArticleServer)
