import { useQuery } from '@tanstack/react-query'
import getArticle from '../../worker/fnx/getArticle'
import { useAdmin } from './admin'

export function useArticle(slug: string) {
  const adminConfig = useAdmin()
  return useQuery(['article', slug], async () =>
    getArticle({ ...adminConfig, slug })
  ).data!
}
