import { createContext, ReactNode, useContext } from 'react'

export interface AdminConfig {
  adminKey?: string
}

const AdminContext = createContext<AdminConfig>({})

export function AdminProvider({
  config,
  children,
}: {
  config: AdminConfig
  children: ReactNode
}) {
  return (
    <AdminContext.Provider value={config}>{children}</AdminContext.Provider>
  )
}

export function useAdmin() {
  return useContext(AdminContext)
}
