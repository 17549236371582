export default function NotFound() {
  return (
    <div className="minh-100 padding-top-navbar text-center">
      <h1 className="mt-5">
        <span className="text-primary">4</span>
        <span className="text-secondary">0</span>
        <span className="text-primary">4</span>
        <span className="text-primary"> - Pagina non trovata</span>
      </h1>
    </div>
  )
}
