import { useConfig } from '../../hooks/config'
import styles from './Home.module.css'

export default function Home() {
  const config = useConfig()
  return (
    <div className={styles.HomeContainer}>
      <div className={styles.HomeBg}>
        <img
          className={styles.HomeImage}
          src={config.homeImage?.file?.url + '?w=2500&q=90'}
          alt="studio"
        />
        <div className={styles.HomeBgBack} />
      </div>
      <div className={styles.HomeWall}>
        <h1 className={styles.Tagline}>Studio Legale Avvocato Micol Missana</h1>
        <h4 className={styles.TaglineSub}>{config.homeText}</h4>
      </div>
    </div>
  )
}
