import { Component, ReactNode, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import NotFound from './NotFound'

interface Props {
  children: ReactNode
}

function ResetErrorOnLocationChange({ onReset }: { onReset(): void }) {
  const location = useLocation()
  const prevLocationRef = useRef(location)
  useEffect(() => {
    if (location !== prevLocationRef.current) {
      prevLocationRef.current = location
      onReset()
    }
  }, [location, onReset])
  return null
}

export default class ErrorBoundary extends Component<Props> {
  state: {
    error: Error | null
  }

  constructor(props: Props) {
    super(props)
    this.state = { error: null }
  }

  static getDerivedStateFromError(error: Error) {
    return { error }
  }

  onReset = () => {
    this.setState({ error: null })
  }

  render() {
    if (this.state.error) {
      if ((this.state as any).error?.response?.status === 404) {
        return (
          <>
            <NotFound />
            <ResetErrorOnLocationChange onReset={this.onReset} />
          </>
        )
      }
      return (
        <>
          <div className="container padding-top-navbar minh-100 text-center">
            <h1 className="mt-5">
              <span className="text-primary">Qualcosa è andato storto 😭</span>
            </h1>
          </div>
          <ResetErrorOnLocationChange onReset={this.onReset} />
        </>
      )
    }

    return this.props.children
  }
}
