import { Fragment } from 'react'
import {
  Document as RichTextDocument,
  BLOCKS,
  Inline,
  Block,
  Text,
  Mark,
} from '@contentful/rich-text-types'

function marksToStyle(marks: Mark[]): Record<string, string> {
  return marks.reduce((o, m) => {
    if (m.type === 'italic') {
      o['fontStyle'] = 'italic'
    } else if (m.type === 'bold') {
      o['fontWeight'] = 'bold'
    } else if (m.type === 'underline') {
      o['textDecoration'] = 'underline'
    }
    return o
  }, {} as Record<string, string>)
}

function containsTableHeaders(block: Block) {
  return block.content.some((c) => c.nodeType === BLOCKS.TABLE_HEADER_CELL)
}

function TopRichBlock({ block }: { block: Block | Inline | Text }) {
  if (Array.isArray((block as Block).content)) {
    // NOTE: Handle fucking edge case for tables
    if (block.nodeType === BLOCKS.TABLE) {
      const rows = (block as Block).content.filter(
        (c) => !containsTableHeaders(c as Block)
      )
      const headers = (block as Block).content.filter((c) =>
        containsTableHeaders(c as Block)
      )
      return (
        <table className="table table-bordered table-responsive table-striped table-hover">
          {headers.length > 0 && (
            <thead>
              {headers.map((r, i) => (
                <TopRichBlock block={r} key={i} />
              ))}
            </thead>
          )}
          {rows.length > 0 && (
            <tbody>
              {rows.map((r, i) => (
                <TopRichBlock block={r} key={i} />
              ))}
            </tbody>
          )}
        </table>
      )
    }
    const children = (block as Block).content.map((b, i) => (
      <TopRichBlock block={b} key={i} />
    ))
    if (block.nodeType === BLOCKS.PARAGRAPH) {
      return <p>{children}</p>
    }
    if (block.nodeType === BLOCKS.HEADING_1) {
      return <h1>{children}</h1>
    }
    if (block.nodeType === BLOCKS.HEADING_2) {
      return <h2>{children}</h2>
    }
    if (block.nodeType === BLOCKS.HEADING_3) {
      return <h3>{children}</h3>
    }
    if (block.nodeType === BLOCKS.HEADING_4) {
      return <h4>{children}</h4>
    }
    if (block.nodeType === BLOCKS.HEADING_5) {
      return <h5>{children}</h5>
    }
    if (block.nodeType === BLOCKS.HEADING_6) {
      return <h6>{children}</h6>
    }
    if (block.nodeType === 'hyperlink') {
      return <a href={block.data.uri}>{children}</a>
    }
    if (block.nodeType === BLOCKS.EMBEDDED_ASSET) {
      return (
        <>
          <img
            style={{ maxWidth: '100%' }}
            src={block.data?.target?.file?.url + '?w=2500&q=90'}
            alt={block.data?.target?.description}
          />
          {children}
        </>
      )
    }
    if (block.nodeType === BLOCKS.UL_LIST) {
      return <ul>{children}</ul>
    }
    if (block.nodeType === BLOCKS.OL_LIST) {
      return <ol>{children}</ol>
    }
    if (block.nodeType === BLOCKS.LIST_ITEM) {
      return <li>{children}</li>
    }
    if (block.nodeType === BLOCKS.TABLE_ROW) {
      return <tr>{children}</tr>
    }
    if (block.nodeType === BLOCKS.TABLE_CELL) {
      return <td>{children}</td>
    }
    if (block.nodeType === BLOCKS.TABLE_HEADER_CELL) {
      return <th>{children}</th>
    }
  }
  if (block.nodeType === 'text') {
    return <span style={marksToStyle(block.marks)}>{block.value}</span>
  }
  return null
}

export default function RichText({ doc }: { doc: RichTextDocument }) {
  return (
    <Fragment>
      {doc.content.map((top, i) => (
        <TopRichBlock key={i} block={top} />
      ))}
    </Fragment>
  )
}
